import { notifications } from '~/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import PasswordForgottenModalMixin from '@/mixins/PasswordForgottenModalMixin'
import RegisterModalMixin from '@/mixins/RegisterModalMixin'
import RequiredSign from '@theme/components/atom/RequiredSign'

export default {
  components: {
    AppButton,
    AppInput,
    AppTitle,
    RequiredSign,
  },
  mixins: [LoadingMixin, PasswordForgottenModalMixin, RegisterModalMixin],
  props: {
    classElementsOutside: {
      type: String,
      default: '',
    },
    classInput: {
      type: String,
      default: 'col-sm-24',
    },
    classLabel: {
      type: String,
      default: 'col-sm-12',
    },
    classRow: {
      type: String,
      default: 'mb-3',
    },
    classWrapper: {
      type: String,
      default: 'p-3',
    },
    redirectPath: {
      type: String,
    },
  },
  data() {
    return {
      input: {
        email: this.$route.query && this.$route.query.email ? this.$route.query.email : '',
        password: '',
        remember: false,
      },
    }
  },
  methods: {
    async login() {
      if (!(await this.$validator.validate())) {
        notifications.danger(this.$validator.errors.items[0].msg)
        return
      }
      this.startLoading()
      const response = await this.$store.dispatch('auth/LOGIN', {
        email: this.input.email,
        password: this.input.password,
        redirect: this.redirectPath,
      })
      if (!response) {
        this.input.password = ''
        this.stopLoading()
      } else {
        notifications.success(this.$t('globals.login.successNotificationText'))
        this.stopLoading()
      }
    },
  },
}

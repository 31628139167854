export default {
  props: {
    disableClick: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    tag() {
      return this.link ? 'a' : 'span'
    },
  },
}

<script>
export default {
  methods: {
    openRegisterModal(e) {
      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
      this.$nextTick(() => {
        this.$nuxt.$emit('closeAllModals')
        this.$nuxt.$emit('openRegisterModal')
      })
    },
  },
}
</script>

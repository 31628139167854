import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AppLink from '@theme/components/atom/AppLink'
import ContactMixin from '~/mixins/ContactMixin'
import Container from '@theme/components/utils/Container'
import HeaderTopLogin from '@theme/components/shop/HeaderTopLogin'
import IconLink from '@theme/components/molecule/IconLink'
import LoginModalMixin from '@/mixins/LoginModalMixin'

export default {
  components: {
    AppLink,
    Container,
    CurrencySwitcher: hydrateWhenVisible(() => import('@theme/components/shop/CurrencySwitcher')),
    Dropdown: () => import('@theme/components/utils/Dropdown'),
    DropdownLinks: () => import('@theme/components/utils/DropdownLinks'),
    HeaderTopLogin,
    IconLink,
    LanguageSwitcher: () => import('@theme/components/shop/LanguageSwitcher'),
    DisplayVATSwitcher: () => import('@theme/components/shop/DisplayVATSwitcher'),
    DisplayBothPricesSwitcher: () => import('@theme/components/shop/DisplayBothPricesSwitcher'),
    LoginModal: () => import('@theme/components/user/LoginModal'),
    LogoutButton: () => import('@theme/components/user/LogoutButton'),
  },
  mixins: [ContactMixin, LoginModalMixin],
  props: {
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
  },
  data() {
    return {
      userMenu: this.$store.state.globals.userMenu,
    }
  },
  methods: {
    handleLoginLinkClick(e) {
      if (this.$themeSettings.components.HeaderTop.loginLink.type !== 'link') {
        e.preventDefault()
        if (this.$themeSettings.components.HeaderTop.loginLink.type === 'modal') {
          this.openLoginModal(e)
        }
      }
    },
  },
}
